<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4">
        User Analytics
        <v-icon large right color="primary lighten-3">
          mdi-google-analytics
        </v-icon>
      </v-card-title>
    </v-card>
    <v-card class="px-3 mt-3" elevation="0">
      <callAnalytics> </callAnalytics>
    </v-card>
  </v-container>
</template>

<script>
import callAnalytics from "@/components/user/callAnalytics.vue";
export default {
  name: "userAnalytics",
  mounted() {},
  data() {
    return {
      loading: false,
    };
  },
  components: {
    callAnalytics,
  },
};
</script>