<template>
  <v-dialog width="35%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        color="primary"
        class="mx-5"
        @click="info()"
      >
        mdi-information-outline
      </v-icon>
    </template>
    <v-card>
      <v-card-title>Call Details </v-card-title>
      <v-divider></v-divider>
      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i">
          <v-list-item-content>
            <v-list-item-icon>{{ i + 1 }}) </v-list-item-icon>
            <v-list-item-title class="text-h6">
              {{ item.callInitiator.name }}
              <v-icon large> mdi-arrow-right </v-icon>
              {{ item.calledUser }}
              {{ item.callStatus }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "callDetails",
  props: {
    callDetails: Object,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    info() {
      console.log("What is call object: ", this.callDetails);
      this.$store
        .dispatch("pullMeetingCodeDetails", this.callDetails.meetingCode)
        .then((response) => {
          this.items = response;
          console.log("Response: ", response);
        });
    },
  },
  //   mounted(){
  //       console.log('What is call object: ', this.callDetails)
  //   }
};
</script>