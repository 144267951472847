<template>
  <v-data-table
    fixed-header
    :items-per-page="-1"
    hide-default-footer
    single-select
    sort-by="timeStamp"
    :sort-desc="true"
    :height="viewPortHeight / 1.75"
    :loading="loading"
    :headers="getHeaders"
    :items="callHistoryList"
    item-key="id"
  >
    <template v-slot:item.to="{ item }">
      <span><v-icon left class="pr-3"> mdi-account </v-icon>{{ item.to }}</span>
      <!-- <v-menu v-else offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="primary--text"> 
            <v-icon left class="pr-2">mdi-account-group</v-icon>
              Requested Users </span>
        </template>
        <v-list>
          <v-list-item v-for="(name, index) in item.to" :key="index">
            <v-list-item-title>{{ name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </template>
    <template v-slot:item.actions="{ item }">
      <call-details :callDetails="item"></call-details>
    </template>
  </v-data-table>
</template>
<script>
import moment from "moment";
import callDetails from "@/components/telepresence/callDetails.vue";
export default {
  name: "callData",
  props: {
    callHistoryList: Array,
    loading: Boolean,
    called: String,
  },
  components: {
    callDetails,
  },
  data() {
    return {
      incomingCallHistoryHeader: [
        { text: "Caller", value: "from" },
        { text: "Date", value: "date" },
        { text: "Start Time", value: "start" },
        { text: "End Time", align: "", value: "end" },
        { text: "Call Status", value: "callStatus" },
        { text: "Call Details", value: "actions" },
      ],
      outgoingCallHistoryHeader: [
        { text: "Requested Users", value: "to" },
        { text: "Date", value: "date" },
        { text: "Start Time", value: "start" },
        { text: "End Time", align: "", value: "end" },
        { text: "Call Status", value: "callStatus" },
        { text: "Call Details", value: "actions" },
      ],
    };
  },
  computed: {
    viewPortHeight() {
      return window.innerHeight;
    },
    getHeaders() {
      if (this.called === "Incoming") {
        return this.incomingCallHistoryHeader;
      } else {
        return this.outgoingCallHistoryHeader;
      }
    },
  },
  methods: {
    getColor(score) {
      if (score >= 76) {
        return "success";
      } else if (score >= 60) {
        return "amber";
      } else {
        return "error";
      }
    },
    getCallInfo(item) {
      console.log("Clicked Call", item);
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).format("L");
    },
    formatDuration(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("mm:ss");
    },
    formatTime(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).format("LT");
    },
    formatStringTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LTS");
    },
    formatDateFromNow(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (typeof val !== "string") return val;
      if (val === null) return val;
      if (val === "") return val;
      if (val.length <= 100) {
        return val;
      }
      return `${val.substring(0, 50)}...`;
    },
  },
};
</script>
