<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab v-for="(item, i) in callTypes" :key="i" @click="type = item">{{
        item
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <call-data
          :loading="loading"
          :callHistoryList="incomingCallHistory"
          :called="type"
        ></call-data>
      </v-tab-item>
      <v-tab-item>
        <call-data
          :loading="loading"
          :callHistoryList="outgoingCallHistory"
          :called="type"
        ></call-data>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import callData from "./callData.vue";
export default {
  components: { callData },
  name: "callAnalytics",
  data() {
    return {
      tab: null,
      callTypes: ["Incoming", "Outgoing"],
      loading: false,
      type: "Incoming",
    };
  },
  computed: {
    ...mapGetters([
      "getIncomingCallingHistory",
      "getOutgoingCallingHistory",
      "allRegisteredUsers",
    ]),
    incomingCallHistory() {
      let callHist = [],
        end;
      this.getIncomingCallingHistory.forEach((element) => {

        if (element.endTime === null) {
          end = "-";
        } else {
          end = moment(element.endTime.toDate()).format("LT");
        }
        callHist.push({
          callType: "Incoming",
          from: element.callInitiator.name,
          attendees: element.conferenceAttendees,
          date: moment(element.startTime.toDate()).format("L"),
          start: moment(element.startTime.toDate()).format("LT"),
          end: end,
          callStatus: element.callStatus,
          meetingCode: element.meetingCode
        });
      });
      return callHist;
    },
    outgoingCallHistory() {
      let callHist = [],
        end
      this.getOutgoingCallingHistory.forEach((element) => {
        //let requestedUsers = []
        // element.calledUser.forEach((user) => {
        //   let calledUser = this.allRegisteredUsers.find(
        //     (regUser) => regUser.id === user
        //   );
        //   requestedUsers.push(calledUser.data().name);
        // });
        if (element.endTime === null) {
          end = "-";
        } else {
          end = moment(element.endTime.toDate()).format("LT");
        }
        callHist.push({
          callType: "Incoming",
          from: element.callInitiator.name,
          attendees: element.conferenceAttendees,
          date: moment(element.startTime.toDate()).format("L"),
          start: moment(element.startTime.toDate()).format("LT"),
          end: end,
          callStatus: element.callStatus,
          to: element.calledUser,
          meetingCode: element.meetingCode
        });
      });
      return callHist;
    },
  },
};
</script>
